import React from "react";
import "../bulma.scss";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import HeroSection from "../components/HeroSection";
import CTABanner from "../components/CtaBanner";
import { useStaticQuery, graphql } from "gatsby";
import ItemColumns from "../components/ItemColumns";
import ContentItemButtons from "../components/ContentItemButtons";
import BorderedTitle from "../components/BorderedTitle";

import Rocket from "../images/svgs/rocket.svg";
import CloudNet from "../images/svgs/cloud-net.svg";
import CloudNetwork from "../images/svgs/cloud-network.svg";
import BulbCog from "../images/svgs/bulb-cog.svg";
import DataCenter from "../images/svgs/data-center.svg";
import CloudBd from "../images/svgs/cloud-bd.svg";
import CloudBdMulti from "../images/svgs/cloud-bd-multi.svg";
import Shield from "../images/svgs/shield.svg";
import NoteCheck from "../images/svgs/note-check.svg";
import CogApps from "../images/svgs/cog-apps.svg";
import CloudMultiCogs from "../images/svgs/cloud-multi-cogs.svg";
import TwinCloudCogs from "../images/svgs/twin-cloud-cogs.svg";
import MultiData from "../images/svgs/multi-data.svg";
import ComputerLock from "../images/svgs/computer-lock.svg";
import CloudData from "../images/svgs/cloud-data.svg";
import DisplayCog from "../images/svgs/display-cog.svg";
import NativeSolutions from "../images/svgs/native-solutions.svg";
import CloudApi from "../images/svgs/cloud-api.svg";
import MobileApps from "../images/svgs/mobile-apps.svg";
import HeadInClouds from "../images/svgs/head-in-clouds.svg";
import Medal from "../images/svgs/medal.svg";
import DataLake from "../images/svgs/data-lake.svg";
import DataCentric from "../images/svgs/data-centric.svg";
import MachineLearn from "../images/svgs/machine-learn.svg";
import ManCloudShare from "../images/svgs/man-cloud-share.svg";
import ChartsAtom from "../images/svgs/charts-atom.svg";
import CloudThink from "../images/svgs/chloud-think.svg";

const EasyCloudPage = () => {
  const { rocketImg, rocketImg2, seoImg } = useStaticQuery(
    graphql`
      query {
        rocketImg: file(relativePath: { eq: "cloud-2_960x720px.png" }) {
          childImageSharp {
            fluid(maxWidth: 990, maxHeight: 620, fit: FILL) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        rocketImg2: file(relativePath: { eq: "cloud-1_960x720px.png" }) {
          childImageSharp {
            fluid(maxWidth: 990, maxHeight: 620, fit: FILL) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        seoImg: file(
          relativePath: { eq: "thumbnail_easy-cloud_500x300px.png" }
        ) {
          childImageSharp {
            fixed(width: 500, height: 300) {
              src
            }
          }
        }
      }
    `
  );
  const easyCloudThemeItems = {
    main: "var(--esx-easy)",
    color: "var(--esx-easy)",
    gradient: "#fafafa",
    textColor: "var(--esx-dark)",
  };
  const easyCloudThemeSections = {
    main: "var(--esx-easy)",
    color: "var(--esx-easy)",
    gradient: "var(--dark-gradient)",
    textColor: "#fafafa",
  };
  const items = [
    {
      icon: <Rocket width="60" height="60" />,
      title: "Migração e Otimização",
      description:
        "Leve suas aplicações para a nuvem e veja seu negócio decolar",
      anchorLink: "/easy-cloud-and-applications#migrate-and-optmize",
    },
    {
      icon: <CloudNetwork width="60" height="60" />,
      title: "Modernização",
      description:
        "Promova a inovação enquanto conquista escalabilidade e reduz custos",
      anchorLink: "/easy-cloud-and-applications#modernization",
    },
    {
      icon: <BulbCog width="60" height="60" />,
      title: "Aplicações Nativas em Nuvem",
      description:
        "Crie aplicações escalonáveis e distribua serviços com agilidade",
      anchorLink: "/easy-cloud-and-applications#native-cloud-apps",
    },
    {
      icon: <DataCenter width="60" height="60" />,
      title: "Big Data & AI",
      description:
        "Adquira visibilidade em métricas sobre tudo o que acontece dentro da Nuvem",
      anchorLink: "/easy-cloud-and-applications#bigdata-and-ai",
    },
  ];
  const contentItems = [
    {
      sectionTitle: "Migração e otimização",
      id: "migrate-and-optmize",
      itemButtons: [
        {
          icon: <CloudNet width="50" height="50" />,
          title: "Migração Lift-and-Shift",
          text:
            "Migre suas aplicações para nuvem utilizando o conceito de IaaS (Infraestrutura como Serviço) sem modificar as aplicações já existentes e mantendo os recursos de infra usados localmente.",
        },
        {
          icon: <CloudBd width="50" height="50" />,
          title: "Bancos de dados como serviço",
          text:
            "Mova a sua base dados para a nuvem utilizando o conceito de PaaS (Plataforma como Serviço), mantendo o mesmo modelo de dados, sem exigir quase nenhuma modificação em suas aplicações.",
        },
        {
          icon: <CloudBdMulti width="50" height="50" />,
          title: "Conteinerização de aplicações",
          text:
            "Defina workloads importantes e estratégicos para sua operação e isole em conteiners utilizando o conceito de PaaS (Plataforma como Serviço).",
        },
      ],
      gains: [
        {
          icon: <Shield width="60" height="60" />,
          title: "Aplicações blindadas",
          description:
            "Suas aplicações rodando dentro de um ambiente com os mais rígidos protocolos de segurança. Mais seguro, impossível.",
        },
        {
          icon: <NoteCheck width="60" height="60" />,
          title: "Diminua seus Custos",
          description:
            "Utilize apenas o que precisar e quando precisar. E se for necessário crescer, escale imediatamente.",
        },
        {
          icon: <CogApps width="60" height="60" />,
          title: "Time to market é agora",
          description:
            "Elimine atrasos referentes a disponibilidade de infraestrutura e acelere a entrega das suas aplicações.",
        },
      ],
    },
    {
      sectionTitle: "Modernização",
      id: "modernization",
      itemButtons: [
        {
          icon: <CloudMultiCogs width="50" height="50" />,
          title: "Aplicações web como PaaS",
          text:
            "Ganhe escala e esteja preparado para as sazonalidades do mercado ao modernizar suas aplicações baseadas em websites e web services utilizando o conceito de PaaS (Plataforma como Serviço). ",
        },
        {
          icon: <TwinCloudCogs width="50" height="50" />,
          title: "Rethink de aplicações",
          text:
            "Repense as aplicações atuais com base em arquiteturas modernas com os mais atuais conceitos de nuvem como containers, Servless e IA. Sustentabilidade de verdade sem prejudicar seu budget.",
        },
        {
          icon: <MultiData width="50" height="50" />,
          title: "Integração de serviços de aplicações",
          text:
            "Diminua custos e melhore o desempenho e qualidade das aplicações adotando serviços na nuvem que substituam funcionalidades críticas das aplicações atuais.",
        },
      ],
      gains: [
        {
          icon: <ComputerLock width="60" height="60" />,
          title: "Durma tranquilo",
          description:
            "Durma tranquilo e sem se preocupar com invasões, privacidade de dados e não conformidades. Você está seguro.",
        },
        {
          icon: <CloudData width="60" height="60" />,
          title: "Esteja flexível",
          description:
            "O mundo muda todos os dias, suas estratégias de negócio também. Direcione de forma fácil e instantânea as necessidades das suas aplicações. É disponibilidade imediata.",
        },
        {
          icon: <DisplayCog width="60" height="60" />,
          title: "Seu tempo para o que interessa",
          description:
            "Plataformas totalmente gerenciadas pelo provedor, sem preocupações com atualizações de sistema operacional, patchs de segurança ou disponibilidade.",
        },
      ],
    },
    {
      sectionTitle: "Aplicações Nativas em Nuvem ",
      id: "native-cloud-apps",
      itemButtons: [
        {
          icon: <NativeSolutions width="50" height="50" />,
          title: "Soluções nativas",
          text:
            "Suas aplicações já nascem no futuro. Imagine aproveitar todo o potencial que a nuvem pode oferecer utilizando tecnologias que ajudam você a crescer exponencialmente, de forma sustentável e segura. ",
        },
        {
          icon: <CloudApi width="50" height="50" />,
          title: "Ecossistemas de negócio via API",
          text:
            "Amplie suas possibilidades e construa ecossistemas baseados em API Economy, integrando parceiros, fornecedores e clientes de forma simples e segura.",
        },
        {
          icon: <MobileApps width="50" height="50" />,
          title: "Aplicações mobile híbridas",
          text:
            "Não importa qual plataforma seu cliente usa, você precisa estar lá. Crie apps universais para ambas as lojas e conte com gestão de recursos de nuvem, códigos fontes e testes integrados de forma robusta e automatizada.",
        },
      ],
      gains: [
        {
          icon: <HeadInClouds width="60" height="60" />,
          title: "Faça parte do novo",
          description:
            "Quando uma aplicação já nasce na nuvem, você está no céu. Abandone as velhas preocupações com segurança, instabilidades, infraestrutura e deploys bomba. O novo pode sair mais barato que o velho. ",
        },
        {
          icon: <Medal width="60" height="60" />,
          title: "Seja lean",
          description:
            "As áreas de negócio cada vez mais vão precisar testar novas estratégias de produtos e serviços.  Com as aplicações nativas na nuvem você tem toda a flexibilidade e estrutura para ser lean. ",
        },
        {
          icon: <Rocket width="60" height="60" />,
          title: "Pense exponencialmente",
          description:
            "Crie agora pensando em tornar-se grande no futuro, utilizando arquiteturas conectadas à infraestrutura altamente disponível, flexível e sustentável, de acordo com seu negócio ou demanda. ",
        },
      ],
    },
    {
      sectionTitle: "Big Data & AI",
      id: "bigdata-and-ai",
      itemButtons: [
        {
          icon: <DataLake width="50" height="50" />,
          title: "Estruturação de Data Lake",
          text:
            "Comece pela base. Crie e implemente a estrutura para organização dos seus dados, bem como os processos de consulta e consolidação das informações para consumo das aplicações.",
        },
        {
          icon: <DataCentric width="50" height="50" />,
          title: "Soluções Data Centric Driven",
          text:
            "Crie aplicações que permitam novas experiências aos seus clientes e amplie as oportunidades de atuação no mercado ou até, novos modelos de negócios",
        },
        {
          icon: <MachineLearn width="50" height="50" />,
          title: "Automação por Machine Learning",
          text:
            "Leve suas aplicações para uma nova geração e aproveite engenharias, arquiteturas e tecnologias que já nasceram no conceito de AI, Machine Learning e Big Data.",
        },
      ],
      gains: [
        {
          icon: <ManCloudShare width="60" height="60" />,
          title: "Empatia real",
          description:
            "Descubra padrões de comportamentos, tenha insights a partir das análises dos seus dados e crie ações pró -ativas que surpreendam. Mostre que você conhece e entende seu cliente. ",
        },
        {
          icon: <ChartsAtom width="60" height="60" />,
          title: "Transforme seu negócio",
          description:
            "Crie aplicações que permitam novas experiências aos seus clientes e amplie as oportunidades de atuação no mercado ou até, novos modelos de negócios.",
        },
        {
          icon: <CloudThink width="60" height="60" />,
          title: "Participe da nova geração",
          description:
            "Leve suas aplicações para uma nova geração  e aproveite engenharias, arquiteturas e tecnologias que já nasceram no conceito de AI, Machine Learning e Big Data. ",
        },
      ],
    },
  ];

  return (
    <Layout>
      <SEO
        title="Easy Cloud and Applications"
        image={seoImg}
        description="Crie e construa aplicações sustentáveis utilizando toda propulsão que a Cloud e a Inteligência Artificial podem oferecer e chegue aonde quiser.
"
      />
      <HeroSection
        theme={easyCloudThemeSections}
        title="Easy Cloud and Applications"
        text="Crie e construa aplicações sustentáveis utilizando toda propulsão que a Cloud e a Inteligência Artificial podem oferecer e chegue aonde quiser.<br/><br/>
        Um conjunto de serviços que se adaptam a sua estratégia de crescimento e inovação sem deixar de observar e respeitar o seu cenário atual.
        "
        fixedImage={rocketImg.childImageSharp.fluid}
        buttonText="Fale agora com a gente"
        buttonLink="/contato-easy-cloud"
        openAsExternalLink
      />
      <ItemColumns items={items} theme={easyCloudThemeItems} disableAnimation />
      <CTABanner
        title="Planeje hoje o futuro das suas aplicações na Nuvem"
        subtitle="Utilize seus recursos de maneira prática e sustentável."
        buttonText="Fale agora com a gente"
        theme={{ main: "var(--esx-easy)" }}
        buttonLink="/contato-easy-cloud"
        openAsExternalLink
      />

      {contentItems.map((ci, key) => (
        <>
          <hr id={ci.id} style={{ margin: 0 }}></hr>
          <ContentItemButtons
            sectionTitle={ci.sectionTitle}
            key={key}
            items={ci.itemButtons}
            externalKey={key}
            theme={easyCloudThemeSections}
          />
          <BorderedTitle
            title="Ganhos"
            borderColor={easyCloudThemeItems.color}
            align="center"
            dense
          />
          <ItemColumns items={ci.gains} theme={easyCloudThemeItems} />
        </>
      ))}

      <HeroSection
        theme={easyCloudThemeSections}
        title="Conheça a rota mais segura para a Nuvem"
        text="Inove utilizando como ferramenta todo o potencial da tecnologia Cloud Computing. Promova inovações com mais agilidade, colaboração e inteligência, para construir negócios que atraiam clientes, enquanto alcançam escalabilidade e geram redução de custos.<br/><br/> Iremos levar o seu negócio para a nuvem da maneira mais inteligente e segura, através de abordagens estratégicas e sustentáveis."
        fixedImage={rocketImg2.childImageSharp.fluid}
        buttonText="Fale agora com a gente"
        buttonLink="/contato-easy-cloud"
        openAsExternalLink
      />
    </Layout>
  );
};

export default EasyCloudPage;
